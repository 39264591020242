import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addItemToCart } from "../../services/ItemService";
import { AppContext } from "../../utils/AppProvider";
import { isLogin } from "../../utils/constant";

const PromotedItems = (props) => {
  const [items, setItems] = useState([]);
  const [wishlistItems, setwishlistItems] = useState([]);
  const { setCartQty, cart_qty, wishlist_qty, setWishlistQty } = useContext(AppContext);

  useEffect(() => {
    getWishlist();
    if (props.items?.length > 0) {
      setItems(props.items);
    }
  }, [props]);

  const getWishlist = async () => {
    let items = await localStorage.getItem('wishlist_items')

    if (items != null) {
      items = JSON.parse(items);
      let wishlist_item_ids = items.map((_item) => { return _item.id })
      setwishlistItems(wishlist_item_ids);
    }
  }

  const toggleWishlist = async (item, e) => {
    e.preventDefault();
    let items = await localStorage.getItem('wishlist_items')
    let set = 0;
    let date = new Date();
    date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDay() + ", " + date.getFullYear();

    if (items != null) {
      items = JSON.parse(items);
      let ids = items.map(item => item['id']);

      if (ids.includes(item.id)) {
        let wishlist_items = items.filter((_item) => _item.id != item.id)
        let wishlist_item_ids = wishlist_items.map((_item) => { return _item.id })
        set = -1;
        setwishlistItems(wishlist_item_ids);
        localStorage.setItem('wishlist_items', JSON.stringify(wishlist_items));
        toast("Item Removed from Wishlist", { type: 'success' });
      } else {
        item['wishlist_added_date'] = date;
        items.push(item)
        set =1;
        setwishlistItems([...wishlistItems, item.id])
        localStorage.setItem('wishlist_items', JSON.stringify(items));
        toast("Item added into Wishlist", { type: 'success' });
      }
    } else {
      let temp = [];
      item['wishlist_added_date'] = date;
      temp.push(item)
      set =1;
      setwishlistItems([item.id])
      localStorage.setItem('wishlist_items', JSON.stringify(temp));

      toast("Item added into Wishlist", { type: 'success' });
    }
    setWishlistQty(wishlist_qty + set);
  }

  const handleAddToCart = (item, e) => {
    e.preventDefault();
    if(isLogin()){
       let item_id = item.id;
       addItemToCart(item_id)
          .then((response) => {
             setCartQty(parseInt(cart_qty) + 1);
             toast('Item Added To Cart Successfully.',{type: 'success'})
          })
          .catch((error) => {
             console.log("Error", error);
             if(error.response.status == 422)
                  toast(error.response.data.message,{type: 'error'})
              else if(error.response.status == 500)
                  toast(error.response.data.message,{type: 'error'})
              else    
                  toast("Something went wrong", {type: 'error'});
          })
    }else{
       toast('Please login to add item to cart',{type:'error'});
    }
 }

  return (

    items.length > 0 ?
      <section className="section-padding pb-5">
        <div className="container">
          <div className="section-title">
            <h3 className="">Promoted Items</h3>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="tab-content" id="myTabContent-1">
                <div
                  className="tab-pane fade show active"
                  id="tab-one-1"
                  role="tabpanel"
                  aria-labelledby="tab-one-1"
                >
                  <div className="carausel-4-columns-cover arrow-center position-relative">
                    <div
                      className="slider-arrow slider-arrow-2 carausel-4-columns-arrow"
                      id="carausel-4-columns-arrows"
                    />
                    <div
                      className="carausel-4-columns carausel-arrow-center"
                      id="carausel-4-columns"
                    >
                      <div className="slick-list draggable">
                        {
                          items.map((item, index) => {
                            return (
                              <div key={index} className="product-cart-wrap slick-slide slick-active  ">
                                <div className="product-img-action-wrap">
                                  <div className="product-img product-img-zoom">
                                    <Link to={"/product/" + item.id + "/" + item.item_name}>
                                      <img
                                        className="default-img"
                                        src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                  <div className="product-action-1">
                                    <a
                                      aria-label="Add To Wishlist"
                                      className="action-btn small hover-up"
                                      href=""
                                      onClick={(e) => { toggleWishlist(item, e) }}
                                    >
                                      {
                                        wishlistItems.includes(item.id) ?
                                          <i className="wisilist-fill">&#10084;</i>
                                          :
                                          <i className="fi-rs-heart" />
                                      }
                                    </a>
                                  </div>
                                  <div className="product-badges product-badges-position product-badges-mrg">
                                    <span className="hot">Save 15%</span>
                                  </div>
                                </div>
                                <div className="product-content-wrap">
                                  <div className="product-category">
                                    <Link to={"/product-category/" + item.category?.slug}>{item.category?.category_name}</Link>
                                  </div>
                                  <h2>
                                    <Link to={"/product/" + item.id + "/" + item.item_name}>
                                      {item.item_name}
                                    </Link>
                                  </h2>
                                  <div className="product-rate d-inline-block">
                                    <div
                                      className="product-rating"
                                      style={{ width: "80%" }}
                                    />
                                  </div>
                                  <div className="product-price mt-10">
                                    <span>${item.purchase_price} </span>
                                    <span className="old-price">${item.sales_price}</span>
                                  </div>

                                  <a href="" onClick={(e) => handleAddToCart(item,e)} className="btn w-100 hover-up">
                                    <i className="fi-rs-shopping-cart mr-5" />
                                    Add To Cart
                                  </a>
                                </div>
                              </div>
                            );
                          })
                        }

                      </div>
                    </div>
                  </div>
                </div>
                {/*End tab-pane*/}

              </div>
              {/*End tab-content*/}
            </div>
            {/*End Col-lg-9*/}
          </div>
        </div>
      </section>
      : null


  );
}

export default PromotedItems;