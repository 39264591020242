import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { register } from "../../services/AuthService";
import { getAllCountries } from "../../services/CountryService";
import { getAllStates } from "../../services/StateService";
import { AppContext } from "../../utils/AppProvider";
import { randomSecurityCode } from "../../utils/constant";

const Register = () => {
    const [securityCode,setSecurityCode] = useState('');
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isAgree, setIsAgree] = useState(false);
    const [state, setState] = useState({
        title: '',
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        address: '',
        city: '',
        state_id: '',
        country_id:'',
        tax_id: '',
        password: '',
        confirm_password: '',
        phone:'',
        code:''
    });
    const { setIsAuthenticated } = useContext(AppContext);

    const navigate = useNavigate();

    useEffect(() => {
        // getStates();
        getCountries();
        setSecurityCode(randomSecurityCode());
    }, []);

    const getStates = async (country_id = '') => {
        await getAllStates(country_id).then((response) => {
            setStates(response.data);
        }).catch((error) => {

        });
    }
    
    const getCountries = async () => {
        await getAllCountries().then((response) => {
            setCountries(response.data.data);
        }).catch((error) => {

        });
    }

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        
        if(name == 'country_id'){
            getStates(value);
        }

        setState({ ...state, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateInput()) {
            await register(state).then((response) => {
                localStorage.setItem('is_authenticated', 1);
                localStorage.setItem('token', response.data.data.token);
                setIsAuthenticated(1);
                toast('Customer Registered Successfully', { type: 'success' })

                navigate("/");
            }).catch((error) => {
                if (error.response.status == 422)
                    toast(error.response.data.error, { type: 'error' })
                else if (error.response.status == 500)
                    toast(error.response.data.message, { type: 'error' })
                else
                    toast("Something went wrong", { type: 'error' });
            });
        }
    }

    const validateInput = () => {
        if (state.title == '') {
            toast("Please Select Title.", {
                type: 'error'
            });
            return false
        }
        if (state.name == '') {
            toast("Please enter name.", {
                type: 'error'
            });
            return false
        }
        if (state.email == '') {
            toast("Please enter email.", {
                type: 'error'
            });
            return false
        }
        if (state.company_name == '') {
            toast("Please enter company name.", {
                type: 'error'
            });
            return false
        }
        if (state.password == '') {
            toast("Please enter password.", {
                type: 'error'
            });
            return false
        }
        if(state.confirm_password == ''){
            toast("Please enter confirm password.",{
                type:'error'
            });
            return false
        }

        if(state.confirm_password !== state.password){
            toast("Password and confirm password does not match.",{
                type:'error'
            });
            return false
        }
        
        if(!isAgree){
            toast("Please accept the terms and conditions.",{
                type:'error'
            });
            return false
        }
        
        if(state.code != securityCode){
            toast("Security code does not match",{
                type:'error'
            });
            return false
        }

        return true;
    }

    return (
        <main className="main pages">
            <div className="page-header breadcrumb-wrap">
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/" rel="nofollow">
                            <i className="fi-rs-home mr-5" />
                            Home
                        </Link>
                        <span /> Registration
                    </div>
                </div>
            </div>
            <div className="page-content pt-80 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                            <div className="row">
                                <div className="col-lg-6 col-md-6" style={{paddingTop:'10%'}}>
                                <div className="heading_s1">
                                    <h1 className="mb-5">Create an Account</h1>
                                    
                                </div>
                                <p className="mb-30">
                                        Already have an account?{" "}
                                        <Link to="/login">Login</Link>
                                    </p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <video
                                        loading="lazy"
                                        muted="muted"
                                        src="images/register-illustartion.mp4"
                                        type="video/mp4"
                                        autoPlay="autoplay"
                                        loop="loop"
                                        style={{ width: '100%' }}
                                    ></video>
                                </div>
                                
                            </div>
                            <div className="row">
                            
                                <div className="col-lg-12 col-md-12">
                                    <div className="login_wrap widget-taber-content background-white">
                                        <div className="padding_eight_all bg-white">
                                            
                                            <form method="post">
                                                <div className="row">
                                                    <div className="form-group col-lg-6 col-md-6 ">
                                                        <div className="row">

                                                        
                                                        <div className="col-md-3">
                                                        <select className="form-control" style={{height:'64px'}} name="title" value={state.title} onChange={(e)=> handleInputChange(e)}>
                                                            <option value="">Title</option>
                                                            <option value="Mr">Mr</option>
                                                            <option value="Ms">Ms</option>
                                                            <option value="Mrs">Mrs</option>
                                                            <option value="Miss">Miss</option>
                                                            
                                                        </select>
                                                        </div>
                                                        <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            required=""
                                                            name="first_name"
                                                            value={state.first_name}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="First Name *"
                                                        />
                                                        </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                    <div className="form-group col-lg-6 col-md-6">
                                                        <input
                                                            type="text"
                                                            required=""
                                                            name="last_name"
                                                            value={state.last_name}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="Last Name *"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-lg-6 col-md-6">
                                                        <input
                                                            type="text"
                                                            required=""
                                                            name="email"
                                                            value={state.email}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="Email *"
                                                        />
                                                    </div>
                                                    <div className="form-group  col-lg-6 col-md-6">
                                                        <input
                                                            type="text"
                                                            required=""
                                                            name="tax_id"
                                                            value={state.tax_id}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="Tax ID"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group  col-lg-6 col-md-6">
                                                        <input
                                                            required=""
                                                            type="password"
                                                            name="password"
                                                            value={state.password}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="Password *"
                                                        />
                                                    </div>
                                                    <div className="form-group  col-lg-6 col-md-6">
                                                        <input
                                                            required=""
                                                            type="password"
                                                            name="confirm_password"
                                                            value={state.confirm_password}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="Confirm password *"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group  col-lg-6 col-md-6">
                                                        <input
                                                            type="text"
                                                            required=""
                                                            name="company_name"
                                                            value={state.company_name}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="Company Name *"
                                                        />
                                                    </div>
                                                    <div className="form-group  col-lg-6 col-md-6">
                                                        <input
                                                            type="text"
                                                            required=""
                                                            name="phone"
                                                            value={state.phone}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="Phone"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group  col-lg-12 col-md-12">
                                                    <textarea
                                                        style={{height:'100px',minHeight:'100px'}}
                                                        required=""
                                                        name="address"
                                                        rows={2}
                                                        value={state.address}
                                                        onChange={(e) => handleInputChange(e)}
                                                        placeholder="Address"
                                                    ></textarea>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group  col-lg-6 col-md-6">
                                                        <input
                                                            type="text"
                                                            required=""
                                                            name="city"
                                                            value={state.city}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="City"
                                                        />
                                                    </div>
                                                    
                                                    <div className="form-group  col-lg-6 col-md-6">
                                                        <select className="form-control" style={{height:'64px'}} name="country_id" value={state.country_id_id} onChange={(e)=> handleInputChange(e)}>
                                                        <option value="">Select Country</option>
                                                        {
                                                            countries.map((country,index)=>{
                                                                return <option key={index} value={country.id}> {country.name} </option>
                                                            })
                                                        }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group  col-lg-6 col-md-6">
                                                        <select className="form-control" style={{height:'64px'}} name="state_id" value={state.state_id} onChange={(e)=> handleInputChange(e)} >
                                                        <option value="">Select State</option>
                                                        {
                                                            states.map((state,index)=>{
                                                                return <option key={index} value={state.id}> {state.name} </option>
                                                            })
                                                        }
                                                        </select>
                                                    </div>
                                                    <div className="chek-form col-lg-6 col-md-6">
                                                        <input
                                                            type="text"
                                                            required=""
                                                            name="code"
                                                            value={state.code}
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder="Security code *"
                                                        />
                                                    </div>
                                                </div>
                                                
                                                
                                               <div className="row">
                                                    <div className="custome-checkbox col-lg-6 col-md-6">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="checkbox"
                                                            id="exampleCheckbox12"
                                                            defaultValue=""
                                                            checked={isAgree}
                                                            onChange={(e) => (e.target.checked) ? setIsAgree(true) : setIsAgree(false)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleCheckbox12"
                                                        >
                                                            <span>I agree to terms &amp; Policy.</span>
                                                        </label> <br />
                                                        <Link to="/privacy-policy">
                                                        <i className="fi-rs-book-alt mr-5 text-muted" />
                                                        Lean more
                                                    </Link>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                    <span className="security-code">
                                                        {
                                                            securityCode.split("").map((num,index) => {
                                                                return index == 0 ? <b key={index} className="text-new">{num}</b>
                                                                : index == 1 ? <b key={index} className="text-hot">{num}</b>
                                                                : index == 2 ? <b key={index} className="text-sale">{num}</b>
                                                                : <b key={index} className="text-best">{num}</b>;
                                                            })
                                                        }
                                                    </span>
                                                    </div>
                                               </div>
                                                
                                                <div className="form-group mb-30">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-fill-out btn-block hover-up font-weight-bold"
                                                        name="login"
                                                        onClick={(e) => handleSubmit(e)}
                                                    >
                                                        Submit &amp; Register
                                                    </button>
                                                </div>
                                                <p className="font-xs text-muted">
                                                    <strong>Note:</strong>Your personal data will be used to
                                                    support your experience throughout this website, to manage
                                                    access to your account, and for other purposes described
                                                    in our privacy policy
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    );
}

export default Register;