import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveAddreses } from "../../services/CustomerService";
import Navigation from "./Navigation";

const Address = () => {
  const [billingAdd, setBillingAdd] = useState({});
  const [shippingAdd, setShippingAdd] = useState({});

  useEffect(() => {
    getAddresses();
  }, []);

  const getAddresses = async () => {
    await retriveAddreses().then((response) => {
      if (response.data) {
        let data = response.data.data;

        let bill_add = {
          address: data.address,
          city: data.city,
          state_id: data.state_id,
          state_name: data.state.name,
          pincode: data.pincode,
        }

        let shipp_add = {};
        if (data.hasOwnProperty('addresses') && data.addresses.length > 0) {
          let temp = data.addresses[0];
          shipp_add = {
            address: temp.address,
            city: temp.city,
            state_id: temp.state_id,
            pincode: temp.pincode,
            state_name: temp.state?.name,
            shipp_add_id: temp.id,
          }
          console.log("Temp", temp, shipp_add);
        }

        setBillingAdd(bill_add);
        setShippingAdd(shipp_add);
      }
    }).catch((error) => {
      console.log("Error", error.response)
      if (error.response.status == 422)
        toast(error.response.data.error, { type: 'error' })
      else if (error.response.status == 500)
        toast(error.response.data.message, { type: 'error' })
      else
        toast("Something went wrong", { type: 'error' });
    });
  }

  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="index.html" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </a>
            <span /> Addresses
          </div>
        </div>
      </div>
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <Navigation />
                <div className="col-md-9">
                  <div className="tab-content account dashboard-content pl-50">
                    
                    <div
                      className="tab-pane fade active show"
                      id="address"
                      role="tabpanel"
                      aria-labelledby="address-tab"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="card mb-3 mb-lg-0">
                            <div className="card-header">
                              <h3 className="mb-0">Billing Address</h3>
                            </div>
                            <div className="card-body">
                              <address>
                              { billingAdd.address} <br />
                              { billingAdd.city} <br />
                              { billingAdd.state_name} <br />
                              { billingAdd.pincode} <br />
                              </address>
                              <Link to={"/my-account/edit-address/billing?add=" + JSON.stringify(billingAdd)} className="btn-small">
                                Edit
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="card-header">
                              <h3 className="mb-0">Shipping Address</h3>
                            </div>
                            <div className="card-body">
                              <address>
                              {
                            Object.keys(shippingAdd).length > 0 ? <>
                            {shippingAdd?.address} <br />
                            { shippingAdd?.city} <br />
                            { shippingAdd?.state_name} <br />
                            { shippingAdd?.pincode} <br /> </>
                            : 'No Address Available'}
                              </address>
                              <Link to={"/my-account/edit-address/shipping?add=" + JSON.stringify(shippingAdd)} className="btn-small">
                                Edit
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

  );
}

export default Address;