import { useContext } from "react";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom"
import { retriveCategories } from "../../services/CategoryService";
import { AppContext } from "../../utils/AppProvider";

const HeaderBottom = () => {
    const [showAllCategory, setShowAllCategory] = useState(false);
    const [showAllCat, setShowAllCat] = useState(false);
    const [categories, setcategories] = useState([]);
    const [parentCatId, setParentCatId] = useState('');
    const location = useLocation();
    const { cart_qty, wishlist_qty } = useContext(AppContext);

    useEffect(()=>{
        getCategories();
     },[]);
     
     useEffect(()=>{
        setShowAllCategory(false);
        setShowAllCat(false);
        setParentCatId(null);
     },[ location]);

     const getCategories = async() =>{
        await retriveCategories().then((response)=>{
           if(response.data.data){
              setcategories(response.data.data);
           }
       }).catch((error) =>{
           console.log("Error", error.response)
       });
     }

    const toggleCategoryDropDown = (e) => {
        e.preventDefault();
        setShowAllCategory(!showAllCategory);
        setParentCatId(null);
    }

    return (
        <div className="header-bottom header-bottom-bg-color sticky-bar">
            <div className="container">
                <div className="header-wrap header-space-between position-relative">
                    <div className="logo logo-width-1 d-block d-lg-none">
                        <Link to="/">
                            <img src="/images/theme/logo.svg" alt="logo" />
                        </Link>
                    </div>
                    <div className="header-nav d-none d-lg-flex" id="navbar-bottom-categories">
                        <div className="main-categori-wrap d-none d-lg-block">
                            <a className={"categories-button-active" + (showAllCategory ? ' open' : '') } href="" onClick={(e) => toggleCategoryDropDown(e)}>
                                <span className="fi-rs-apps" /> <span className="et">Browse</span>{" "}
                                All Categories
                                <i className="fi-rs-angle-down" />
                            </a>
                            <div className={"categories-dropdown-wrap categories-dropdown-active-large font-heading" + (showAllCategory ? ' open' : '') + (showAllCat ? ' more-cat-box' : '') }>
                                <div className="d-flex categori-dropdown-inner" style={{minWidth:parentCatId && showAllCat ? '470px' : 'max-content'}}>
                                    <ul>
                                       {
                                        categories.map((category, index) => {
                                            if(showAllCat){
                                                return (
                                                    <div key={index}>
                                                    <li onMouseEnter={() => setParentCatId(category.id)} className="parent-cat" key={index}>
                                                    <Link to={"/product-category/" + (category.slug)}>
                                                        {" "}
                                                        <img src="/images/theme/icons/category-6.svg" alt="" />
                                                        { category.category_name }
                                                    </Link>
                                                    </li>
                                                    {
                                       (categories.filter((el) => el.parent_id == category.id).length > 0 && parentCatId == category.id) ?
                                       
                                          <ul style={{left:parentCatId && showAllCat ? '52%' : '93%',top:parentCatId && showAllCat ? '6%' : '0'}} className={"sub-menu show_cat"}>
                                             {categories.map((sub_cat, _index) => {
                                                
                                                if(sub_cat.parent_id == category.id && parentCatId == category.id){
                                                return (

                                                   <li key={"subcat-" + _index } className="sub-cat">
                                                      <Link to={"/product-category/" + category.slug  + "/" + sub_cat.slug}>
                                                        <img src="/images/theme/icons/category-6.svg" alt="" />
                                                         {sub_cat.category_name}
                                                      </Link>
                                                   </li>
                                                )}
                                             })}
                                          </ul>

                                          : null
                                    }
                                                
                                                </div>
                                            );
                                                }else{
                                                    if(index <= 4){
                                                        return (
                                                            <div key={index}>
                                                            <li onMouseEnter={() => setParentCatId(category.id)} className="parent-cat" key={index}>
                                                            <Link to={"/product-category/" + (category.slug)}>
                                                                {" "}
                                                                <img src="/images/theme/icons/category-6.svg" alt="" />
                                                                { category.category_name }
                                                            </Link>
                                                            </li>
                                                            {
                                               (categories.filter((el) => el.parent_id == category.id).length > 0 && parentCatId == category.id) ?
                                               
                                                  <ul className={"sub-menu show_cat"}>
                                                     {categories.map((sub_cat, _index) => {
                                                        
                                                        if(sub_cat.parent_id == category.id && parentCatId == category.id){
                                                        return (
        
                                                           <li key={"subcat-" + _index } className="sub-cat">
                                                              <Link to={"/product-category/" + category.slug  + "/" + sub_cat.slug}>
                                                                <img src="/images/theme/icons/category-6.svg" alt="" />
                                                                 {sub_cat.category_name}
                                                              </Link>
                                                           </li>
                                                        )}
                                                     })}
                                                  </ul>
        
                                                  : null
                                            }
                                                        
                                                        </div>
                                                    ); 
                                                    }
                                                }
                                        })
                                       } 
                                        
                                        
                                    </ul>
                                  {/*   <ul className="end">
                                    {
                                        categories.map((category, index) => {
                                            if(showAllCat && index > categories.length /2){
                                                console.log("LEN", categories.length);
                                                return (
                                                    <li key={index}>
                                                    <Link to={"/product-category/" + (category.slug)}>
                                                        {" "}
                                                        <img src="/images/theme/icons/category-6.svg" alt="" />
                                                        { category.category_name }
                                                    </Link>
                                                </li>
                                            );
                                                }else{
                                                    if(!showAllCat && index > 4 && index <= 9){
                                                     return (
                                                        <li key={index}>
                                                    <Link to={"/product-category/" + (category.slug)}>
                                                        {" "}
                                                        <img src="/images/theme/icons/category-6.svg" alt="" />
                                                        { category.category_name }
                                                    </Link>
                                                </li>
                                                     );   
                                                    }
                                                }
                                        })
                                       }
                                        
                                    </ul> */}
                                </div>

                                <div onClick={() => setShowAllCat(!showAllCat)} className={"more_categories" + (showAllCat ? ' show' : '')}>
                                    <span className="icon" />{" "}
                                    <span className="heading-sm-1"> {showAllCat ? 'See less...' : 'Show more...' }</span>
                                </div>
                            </div>
                        </div>
                        <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                            <nav>
                                <ul>   
                                    <li>
                                        <Link to="/"> Home </Link>
                                    </li>

                                    <li>
                                        <Link to="/product-category/edibles">Edibles</Link>
                                    </li>
                                    
                                    <li>
                                        <Link to="/product-category/flower-pre-rolls"> Flower & Pre-Rolls</Link>
                                    </li>
                                    
                                    <li>
                                        <Link to="/product-category/tinctures">Tinctures</Link>
                                    </li>
                                    
                                    <li>
                                        <Link to="/product-category/vape">Vape</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                   
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="hotline d-none d-lg-flex">
                        <img src="/images/theme/icons/icon-headphone.svg" alt="hotline" />
                        <p>
                            1900 - 888<span>24/7 Support Center</span>
                        </p>
                    </div>
                    <div className="header-action-icon-2 d-block d-lg-none">
                        <div className="burger-icon burger-icon-white">
                            <span className="burger-icon-top" />
                            <span className="burger-icon-mid" />
                            <span className="burger-icon-bottom" />
                        </div>
                    </div>
                    <div className="header-action-right d-block d-lg-none">
                        <div className="header-action-2">
                            <div className="header-action-icon-2">
                                <Link to="/wishlist">
                                    <img alt="Nest" src="/images/theme/icons/icon-heart.svg" />
                                    <span className="pro-count white"> {wishlist_qty} </span>
                                </Link>
                            </div>
                            <div className="header-action-icon-2">
                                <Link className="mini-cart-icon" to="/cart">
                                    <img alt="Nest" src="/images/theme/icons/icon-cart.svg" />
                                    <span className="pro-count white">{cart_qty}</span>
                                </Link>
                                <div className="cart-dropdown-wrap cart-dropdown-hm2 d-none">
                                    <ul>
                                        <li>
                                            <div className="shopping-cart-img">
                                                <a href="#shop-product-right.html">
                                                    <img alt="Nest" src="/images/shop/thumbnail-3.jpg" />
                                                </a>
                                            </div>
                                            <div className="shopping-cart-title">
                                                <h4>
                                                    <a href="#shop-product-right.html">Plain Striola Shirts</a>
                                                </h4>
                                                <h3>
                                                    <span>1 × </span>$800.00
                                                </h3>
                                            </div>
                                            <div className="shopping-cart-delete">
                                                <a href="##">
                                                    <i className="fi-rs-cross-small" />
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="shopping-cart-img">
                                                <a href="#shop-product-right.html">
                                                    <img alt="Nest" src="/images/shop/thumbnail-4.jpg" />
                                                </a>
                                            </div>
                                            <div className="shopping-cart-title">
                                                <h4>
                                                    <a href="#shop-product-right.html">Macbook Pro 2022</a>
                                                </h4>
                                                <h3>
                                                    <span>1 × </span>$3500.00
                                                </h3>
                                            </div>
                                            <div className="shopping-cart-delete">
                                                <a href="##">
                                                    <i className="fi-rs-cross-small" />
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="shopping-cart-footer">
                                        <div className="shopping-cart-total">
                                            <h4>
                                                Total <span>$383.00</span>
                                            </h4>
                                        </div>
                                        <div className="shopping-cart-button">
                                            <a href="#shop-cart.html">View cart</a>
                                            <a href="#shop-checkout.html">Checkout</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default HeaderBottom;