import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCartItems, increaseDecreaseCartItemQty, removeItemFromCart } from "../../services/CartService";
import { AppContext } from "../../utils/AppProvider";

const Cart = () => {
  const [items, setItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [rate, setRate] = useState(0);
  const { cart_qty, setCartQty } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    getCartDetails();
  }, []);

  const getCartDetails = () => {
    getCartItems()
      .then((response) => {
        let st = 0;
        if (response.data) {
          response.data.data.map((i, index) => {
            st += i.quantity * i.item.purchase_price;
          });

          setSubtotal(st.toFixed(2));
          setTotal(st.toFixed(2));
          setItems(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      })
  }

  useEffect(() => {
    let st = 0;
    items.length > 0 && items.map((i) => {
      st += i.quantity * i.item.purchase_price;
    });

    if (st == 0)
      setTotal(0)
    else
      setTotal((st + rate).toFixed(2))
  }, [rate, subtotal]);

  useEffect(() => {
    let st = 0;
    items.map((i) => {
      st += i.quantity * i.item.purchase_price;
    });

    setSubtotal(st.toFixed(2));
    if (st == 0)
      setTotal(0)
    else
      setTotal((st + rate).toFixed(2))
  }, [items]);



  const handleRemoveItem = (e, item) => {
    e.preventDefault();

    removeItemFromCart(item.id)
      .then((response) => {
        let _items = items.filter((i) => i.id != item.id)
        setItems(_items);
        setCartQty(cart_qty - item.quantity);
      })
      .catch((error) => {
        console.log("Error", error);
      })

  }

  const handleRemoveAll = (e) => {
    e.preventDefault();
    removeItemFromCart('all')
      .then((response) => {
        setItems([]);
        setRate(0);
      })
      .catch((error) => {
        console.log("Error", error);
      })
  }

  const decreaseQuantity = (item_id, index) => {
    increaseDecreaseCartItemQty(item_id, false)
      .then((response) => {
        let _items = items.map((it, i) => {
          if (i == index) {
            return { ...it, quantity: it.quantity - 1 };
          }
          return it;
        });

        setItems(_items);
        setCartQty(cart_qty - 1);
      })
      .catch((error) => {
        console.log("Error:", error);
      })
  }

  const increaseQuantity = (item_id, index, item_qty, item_stock, purchase_limit) => {
    if (item_qty < item_stock || (purchase_limit != null && item_qty < purchase_limit)) {
      increaseDecreaseCartItemQty(item_id, true)
        .then((response) => {
          let _items = items.map((it, i) => {
            if (i == index) {
              return { ...it, quantity: it.quantity + 1 };
            }
            return it;
          });

          setItems(_items);
          setCartQty(parseInt(cart_qty) + 1);
        })
        .catch((error) => {
          console.log("Error:", error);
        })
    }
  }

  return (

    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link href="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Cart
          </div>
        </div>
      </div>
      <div className="container mb-80 mt-50">
        <div className="row">
          <div className="col-lg-8 mb-40">
            <h1 className="heading-2 mb-10">Your Cart</h1>
            <div className="d-flex justify-content-between">
              <h6 className="text-body">
                There are <span className="text-brand"> {cart_qty} </span> products in your
                cart
              </h6>
              <h6 className="text-body d-none">
                <a href="#" className="text-muted">
                  <i className="fi-rs-trash mr-5" />
                  Clear Cart
                </a>
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="table-responsive shopping-summery">
              <table className="table table-wishlist">
                <thead>
                  <tr className="main-heading">
                    {/* <th className="custome-checkbox start pl-30">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="checkbox"
                    id="exampleCheckbox11"
                    defaultValue=""
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleCheckbox11"
                  />
                </th> */}
                    <th scope="col" className="text-center" colSpan={2}>
                      Product
                    </th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Subtotal</th>
                    <th scope="col" className="end">
                      Remove
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    items.length > 0 ?
                      items.map((item, index) => {
                        return (
                          <tr key={index} className="pt-30">
                            {/* <td className="custome-checkbox pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id={"cart-ckb-" + index}
                          defaultValue=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"cart-ckb-" + index}
                        />
                      </td> */}
                            <td className="image product-thumbnail pt-40">
                              <Link style={{ marginLeft: '5px' }} to={"/product/" + item.item_id + "/" + item.item.item_name} title={item.item.item_name}>
                                <img width={90} height={90} src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"} alt="#" />
                              </Link>
                            </td>
                            <td className="product-des product-name">
                              <h6 className="mb-5">
                                <Link className="product-name mb-10 text-heading" to={"/product/" + item.item_id + "/" + item.item.item_name} title={item.item.item_name}>
                                  {item.item.item_name}
                                </Link>
                              </h6>
                              <div className="product-rate-cover">
                                <div className="product-rate d-inline-block">
                                  <div
                                    className="product-rating"
                                    style={{ width: "90%" }}
                                  ></div>
                                </div>
                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                              </div>
                            </td>
                            <td className="price" data-title="Price">
                              <h4 className="text-body">${item.item.purchase_price} </h4>
                            </td>
                            <td className="text-center detail-info" data-title="Stock">
                              <div className="detail-extralink mr-15">
                                <div className="detail-qty border radius">
                                  <a href="" onClick={(e) => { e.preventDefault(); if (item.quantity > 1) { decreaseQuantity(item.id, index) } }} className="qty-down">
                                    <i className="fi-rs-angle-small-down" />
                                  </a>
                                  <span className="qty-val">{item.quantity}</span>
                                  <a href="" onClick={(e) => { e.preventDefault(); increaseQuantity(item.id, index, item.quantity, item.item?.stock, item.item?.purchase_limit) }} className="qty-up">
                                    <i className="fi-rs-angle-small-up" />
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td className="price" data-title="Price">
                              <h4 className="text-brand">${(item.item.purchase_price * item.quantity).toFixed(2)} </h4>
                            </td>
                            <td className="action text-center" data-title="Remove">
                              <a href="" onClick={(e) => handleRemoveItem(e, item)} className="text-body">
                                <i className="fi-rs-trash" />
                              </a>
                            </td>
                          </tr>
                        );
                      })
                      :
                      <tr>
                        <td style={{ textAlign: 'center' }} colSpan={6}> <h5> No Items Available in Cart </h5></td>
                      </tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="divider-2 mb-30" />
            <div className="cart-action d-flex justify-content-between">
              <a className="btn" onClick={() => navigate('/')}>
                <i className="fi-rs-arrow-left mr-10" />
                Continue Shopping
              </a>
              <a onClick={(e) => handleRemoveAll(e)} className={"btn  mr-10 mb-sm-15" + (items.length == 0 ? ' disabled' : '')}>
                <i className="fi-rs-trash mr-10" />
                Empty Cart
              </a>
            </div>
            <div className="row mt-50 d-none">
              <div className="col-lg-7">
                <div className="calculate-shiping p-40 border-radius-15 border">
                  <h4 className="mb-10">Calculate Shipping</h4>
                  <p className="mb-30">
                    <span className="font-lg text-muted">Flat rate:</span>
                    <strong className="text-brand">5%</strong>
                  </p>
                  <form className="field_form shipping_calculator">
                    <div className="form-row">
                      <div className="form-group col-lg-12">
                        <div className="custom_select">
                          <select
                            className="form-control select-active w-100 select2-hidden-accessible"
                            data-select2-id={7}
                            tabIndex={-1}
                            aria-hidden="true"
                          >
                            <option value="" data-select2-id={9}>United Kingdom </option>
                          </select>
                          <span
                            className="select2 select2-container select2-container--default"
                            dir="ltr"
                            data-select2-id={8}
                            style={{ width: "520.094px" }}
                          >
                            <span className="selection">
                              <span
                                className="select2-selection select2-selection--single"
                                role="combobox"
                                aria-haspopup="true"
                                aria-expanded="false"
                                tabIndex={0}
                                aria-labelledby="select2-n4i4-container"
                              >
                                <span
                                  className="select2-selection__rendered"
                                  id="select2-n4i4-container"
                                  role="textbox"
                                  aria-readonly="true"
                                  title="United Kingdom"
                                >
                                  United Kingdom
                                </span>
                                <span
                                  className="select2-selection__arrow"
                                  role="presentation"
                                >
                                  <b role="presentation" />
                                </span>
                              </span>
                            </span>
                            <span className="dropdown-wrapper" aria-hidden="true" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-row row">
                      <div className="form-group col-lg-6">
                        <input
                          required="required"
                          placeholder="State / Country"
                          name="name"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <input
                          required="required"
                          placeholder="PostCode / ZIP"
                          name="name"
                          type="text"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="p-40">
                  <h4 className="mb-10">Apply Coupon</h4>
                  <p className="mb-30">
                    <span className="font-lg text-muted">Using A Promo Code?</span>
                  </p>
                  <form action="#">
                    <div className="d-flex justify-content-between">
                      <input
                        className="font-medium mr-15 coupon"
                        name="Coupon"
                        placeholder="Enter Your Coupon"
                      />
                      <button className="btn">
                        <i className="fi-rs-label mr-10" />
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="border p-md-4 cart-totals ml-30">
              <div className="table-responsive">
                <table className="table no-border">
                  <tbody>
                    <tr>
                      <td className="cart_total_label">
                        <h6 className="text-muted">Subtotal</h6>
                      </td>
                      <td className="cart_total_amount">
                        <h4 className="text-brand text-end">${subtotal}</h4>
                      </td>
                    </tr>
                    {/* <tr>
                  <td scope="col" colSpan={2}>
                    <div className="divider-2 mt-10 mb-10" />
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="text-muted">Shipping</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">Free </h5>
                  </td>
                </tr>
                <tr>
                  <td className="cart_total_label">
                    <h6 className="text-muted">Estimate for</h6>
                  </td>
                  <td className="cart_total_amount">
                    <h5 className="text-heading text-end">United Kingdom </h5>
                  </td>
                </tr> */}
                    <tr>
                      <td scope="col" colSpan={2}>
                        <div className="divider-2 mt-10 mb-10" />
                      </td>
                    </tr>
                    <tr>
                      <td className="cart_total_label">
                        <h6 className="text-muted">Total</h6>
                      </td>
                      <td className="cart_total_amount">
                        <h4 className="text-brand text-end">${total}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Link to="/checkout" className={"btn mb-20 w-100" + (items.length <= 0 ? ' disabled' : '')}>
                Proceed To CheckOut
                <i className="fi-rs-sign-out ml-15" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Cart;