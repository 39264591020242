import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveItems = async (data) => {
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/items?category=${data.category}&brands=${data.brands}&per_page=${data.per_page}&page=${data.page}&min_price=${data.min_price}&max_price=${data.max_price}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveRelatedItems = async (category_id, item_id) => {
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/related-items?category_id=${category_id}&except_item=${item_id}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveHomePageItems = async (data) => {
    return new Promise(function (resolve, reject) {
        axios.get('/customer/home-page-items')
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retrivePromotedItems = async () => {
    return new Promise(function (resolve, reject) {
        axios.get('/customer/promoted-items')
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveFrequentlyOrderedItems = async () => {
    return new Promise(function (resolve, reject) {
        axios.get('/customer/frequently-order-items')
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveItem = async (item_id) => {
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/items/${item_id}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveItemByIds = async (item_ids) => {
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/items-by-ids?ids=${item_ids}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveRecentViewedItems = async (item_ids, except_id) => {
    return new Promise(function (resolve, reject) {
        axios.get(`/customer/recent-viewed-items?ids=${item_ids}&except_id=${except_id}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const addItemToCart = async (item_ids, quantity = 1) => {
    let token = await localStorage.getItem('token')
    let headers = {

        'Authorization': `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get(`/customer/add-to-cart?item_id=${item_ids}&quantity=${quantity}`, { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const addSelectedItemToCart = async (item_ids, quantity = 1) => {
    let token = await localStorage.getItem('token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get(`/customer/add-selected-to-cart?item_ids=${item_ids}&quantity=${quantity}`, { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export { retriveItems, retriveItem, retriveHomePageItems, retriveRelatedItems, retriveItemByIds, retriveRecentViewedItems, addItemToCart, addSelectedItemToCart, retrivePromotedItems, retriveFrequentlyOrderedItems };