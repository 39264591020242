import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { login } from "../../services/AuthService";
import { AppContext } from "../../utils/AppProvider";
import { randomSecurityCode } from "../../utils/constant";

const Login = () => {
    const [securityCode, setSecurityCode] = useState('');
    const [state, setState] = useState({
        email: '',
        password: '',
        code: ''
    });
    const { setIsAuthenticated } = useContext(AppContext);

    const navigate = useNavigate();

    useEffect(() => {
        setSecurityCode(randomSecurityCode());
    }, []);

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateInput()) {
            await login(state).then((response) => {
                localStorage.setItem('is_authenticated', 1);
                localStorage.setItem('token', response.data.data.token);
                setIsAuthenticated(1);

                toast('Customer Login Successfully', { type: 'success' })

                navigate("/");
            }).catch((error) => {
                console.log("Error", error.response)
                if (error.response.status == 422)
                    toast(error.response.data.error, { type: 'error' })
                else if (error.response.status == 500)
                    toast(error.response.data.message, { type: 'error' })
                else
                    toast("Something went wrong", { type: 'error' });
            });
        }
    }

    const validateInput = () => {
        if (state.email == '') {
            toast("Please enter email.", {
                type: 'error'
            });
            return false
        }
        if (state.password == '') {
            toast("Please enter password.", {
                type: 'error'
            });
            return false
        }

        if (state.code == '') {
            toast("Please enter security code.", {
                type: 'error'
            });
            return false
        } else if (state.code != securityCode) {
            toast("Security code does not match.", {
                type: 'error'
            });
            return false
        }

        return true;
    }

    return (
        <main className="main pages">
            <div className="page-header breadcrumb-wrap">
                <div className="container">
                    <div className="breadcrumb">
                        <a to="/" rel="nofollow">
                            <i className="fi-rs-home mr-5" />
                            Home
                        </a>
                        <span /> Login
                    </div>
                </div>
            </div>
            <div className="page-content pt-150 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                            <div className="row">
                                <div className="col-lg-6 pr-30 d-none d-lg-block">
                                    {/* <img
                                        className="border-radius-15"
                                        src="assets/imgs/page/login-1.png"
                                        alt=""
                                    /> */}
                                    <video width="100%" loading="lazy" muted="muted" src="/images/login-illustartion.mp4" type="video/mp4" autoPlay="autoplay" loop="loop"></video>
                                </div>
                                <div className="col-lg-6 col-md-8">
                                    <div className="login_wrap widget-taber-content background-white">
                                        <div className="padding_eight_all bg-white">
                                            <div className="heading_s1">
                                                <h1 className="mb-5">Login</h1>
                                                <p className="mb-30">
                                                    Don't have an account?{" "}
                                                    <Link to="/register">Create here</Link>
                                                </p>
                                            </div>
                                            <form method="post">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        required=""
                                                        name="email"
                                                        placeholder="Username or Email *"
                                                        value={state.email}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        required=""
                                                        type="password"
                                                        name="password"
                                                        placeholder="Your password *"
                                                        value={state.password}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                </div>
                                                <div className="login_footer form-group">
                                                    <div className="chek-form">
                                                        <input
                                                            type="text"
                                                            required=""
                                                            name="code"
                                                            placeholder="Security code *"
                                                            value={state.code}
                                                            onChange={(e) => handleInputChange(e)}
                                                        />
                                                    </div>
                                                    <span className="security-code">
                                                        {
                                                            securityCode.split("").map((num, index) => {
                                                                return index == 0 ? <b key={index} className="text-new">{num}</b>
                                                                    : index == 1 ? <b key={index} className="text-hot">{num}</b>
                                                                        : index == 2 ? <b key={index} className="text-sale">{num}</b>
                                                                            : <b key={index} className="text-best">{num}</b>;
                                                            })
                                                        }
                                                    </span>
                                                </div>
                                                <div className="login_footer form-group mb-50">
                                                    {/* <div className="chek-form">
                                                        <div className="custome-checkbox">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="checkbox"
                                                                id="exampleCheckbox1"
                                                                defaultValue=""
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="exampleCheckbox1"
                                                            >
                                                                <span>Remember me</span>
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                    <Link className="text-muted" to="/my-account/lost-password">
                                                        Forgot password?
                                                    </Link>
                                                </div>
                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-heading btn-block hover-up"
                                                        name="login"
                                                        onClick={(e) => handleSubmit(e)}
                                                    >
                                                        Log in
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Login;