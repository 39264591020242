import { useEffect } from "react";
import { useCallback } from "react";
import { useContext } from "react";
import { useState } from "react";
import { retriveAccountDetails } from "../../services/CustomerService";
import { AppContext } from "../../utils/AppProvider";
import HeaderBottom from "./HeaderBottom";
import HeaderMiddle from "./HeaderMiddle";

const Header = () => {
   const [showNotice, setShowNotice] = useState(true);
   const { is_authenticated } = useContext(AppContext);

   useEffect(() => {
      getCustomerStatus();
  }, []);

   const getCustomerStatus = useCallback( () => {
      if(is_authenticated) {
         retriveAccountDetails().then((response) => {

            if (response?.data?.data) {
               let data = response.data.data

               if (data.status !== 'confirmed') {
                  setShowNotice(true)
               }
            }
         }).catch((error) => {
            console.log("Error", error);
         })
      }
      },[ showNotice]
   );

   return (
      <>
         <header className="header-area header-style-1 header-height-2">
            {
               showNotice ?
                  <div className="text-center position-relative px-4 py-1 notice">
                     <span>Your registration is <span className="badge badge-warning">confirmed</span> but not approved, please wait for 48 hours or contact administrator </span>
                  </div>
                  : ''
            }
            <HeaderMiddle />

            <HeaderBottom />
         </header>
      </>
   );
}

export default Header;