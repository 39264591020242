import { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { retriveBrands, retriveSingleCategory } from "../../services/CategoryService";
import { addItemToCart, retriveItems } from "../../services/ItemService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isLogin } from "../../utils/constant";
import { AppContext } from "../../utils/AppProvider";
import { useRef } from "react";

const ProductCategory = () => {

  const [category, setCategory] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const params = useParams();
  const [wishlistItems, setwishlistItems] = useState([]);
  const [brands, setBrands] = useState([]);
  const [filter, setFilter] = useState('Select Filter');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const { setCartQty, cart_qty, wishlist_qty, setWishlistQty } = useContext(AppContext);
  const [showAllBrands, setShowAllBrands] = useState(false);
  const [showSortByItemCount, setShowSortByItemCount] = useState(false);
  const [showSortByList, setShowSortByList] = useState(false);
  const brandRef = useRef()

  useEffect(() => {
    if (params.category) {
      setPage(1);
      setPerPage(10);
      getSubcategories(params.category);

      let brands = '';
      if (selectedBrands.length > 0) {
        brands = selectedBrands.join(",");
      }
      getItems(params.category, brands);
    }
    document.getElementById("all-categories")?.classList.remove("show")

    return () => {
      setSelectedBrands([]);
      setFilter('');
    }
  }, [params.category]);

  useEffect(() => {
    if (!showAllBrands) {
      if (brandRef.current !== undefined) {
        brandRef.current.scrollTop = 0;
      }
    }
  }, [showAllBrands]);

  useEffect(() => {
    let brands = '';
    if (selectedBrands.length > 0) {
      brands = selectedBrands.join(",");
    }
    getItems(params.category, brands);
  }, [perPage, page]);

  useEffect(() => {
    getWishlist();
    getBrands();
  }, []);

  const getBrands = async () => {
    await retriveBrands().then((response) => {
      if (response.data) {
        setBrands(response.data.data);
      }
    }).catch((error) => {
      console.log("Error", error.response)
    });
  }

  const getSubcategories = async (sub_cat) => {
    await retriveSingleCategory(sub_cat).then((response) => {
      if (response.data.data) {
        setCategory(response.data.data);
      }
    }).catch((error) => {
      console.log("Error", error.response)
    });
  }

  const getItems = async (category, brands) => {
    let params = {
      category: category,
      brands: brands,
      max_price: maxPrice,
      min_price: minPrice,
      per_page: perPage,
      page: page
    }
    await retriveItems(params).then((response) => {
      if (response.data.data) {
        setItems(response.data.data);
      }
    }).catch((error) => {
      console.log("Error", error.response)
    });
  }

  const handleCategoryChange = (e) => {
    let { name, value } = e.target;

    if (selectedCategories.includes(value)) {
      setSelectedCategories(selectedCategories.filter(cat => cat != value));
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
  }

  const handleBrandChange = (brand_id) => {
    let value = brand_id;

    if (selectedBrands.includes(value)) {
      setSelectedBrands(selectedBrands.filter(cat => cat != value));
    } else {
      setSelectedBrands([...selectedBrands, value]);
    }
  }

  useEffect(() => {
    // asceding Order
    if (filter === 'Price: Low to High') {
      const sorted_items = [...items].sort((a, b) => a.purchase_price - b.purchase_price);
      setItems(sorted_items);
    }

    // Desceding Order
    if (filter == 'Price: High to Low') {
      const sorted_items = [...items].sort((a, b) => b.purchase_price - a.purchase_price);
      setItems(sorted_items);
    }

    // Released Date Order
    if (filter === 'Release Date') {
      let date1 = '';
      let date2 = '';
      const sorted_items = [...items].sort((a, b) => {
        date1 = Date.parse(b.created_date.concat(" ", b.created_time));
        date2 = Date.parse(a.created_date.concat(" ", a.created_time));
        return date1 - date2
      });
      setItems(sorted_items);
    }
  }, [filter]);

  useEffect(() => {
    let categories = params.category;
    if (selectedCategories.length > 0) {
      categories = selectedCategories.join(",");
    }
    let brands = '';
    if (selectedBrands.length > 0) {
      brands = selectedBrands.join(",");
    }

    getItems(categories, brands);
  }, [selectedCategories, selectedBrands])

  const getWishlist = async () => {
    let items = await localStorage.getItem('wishlist_items')

    if (items != null) {
      items = JSON.parse(items);
      let wishlist_item_ids = items.map((_item) => { return _item.id })
      setwishlistItems(wishlist_item_ids);
    }
  }

  const toggleWishlist = async (item, e) => {
    e.preventDefault();
    let items = await localStorage.getItem('wishlist_items')
    let set = 0;
    let date = new Date();
    date = date.toLocaleString('default', { month: 'long' }) + " " + date.getDay() + ", " + date.getFullYear();

    if (items != null) {
      items = JSON.parse(items);
      let ids = items.map(item => item['id']);

      if (ids.includes(item.id)) {
        let wishlist_items = items.filter((_item) => _item.id != item.id)
        let wishlist_item_ids = wishlist_items.map((_item) => { return _item.id })
        set = -1;
        setwishlistItems(wishlist_item_ids);
        localStorage.setItem('wishlist_items', JSON.stringify(wishlist_items));
        toast("Item Removed from Wishlist", { type: 'success' });
      } else {
        item['wishlist_added_date'] = date;
        items.push(item)
        set = 1;
        setwishlistItems([...wishlistItems, item.id])
        localStorage.setItem('wishlist_items', JSON.stringify(items));
        toast("Item added into Wishlist", { type: 'success' });
      }
    } else {
      let temp = [];
      item['wishlist_added_date'] = date;
      temp.push(item)
      set = 1;
      setwishlistItems([item.id])
      localStorage.setItem('wishlist_items', JSON.stringify(temp));

      toast("Item added into Wishlist", { type: 'success' });
    }
    setWishlistQty(wishlist_qty + set);
  }

  const handleAddToCart = (item, e) => {
    e.preventDefault();
    if (isLogin()) {
      let item_id = item.id;
      addItemToCart(item_id)
        .then((response) => {
          setCartQty(parseInt(cart_qty) + 1);
          toast('Item Added To Cart Successfully.', { type: 'success' })
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response.status == 422)
            toast(error.response.data.message, { type: 'error' })
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: 'error' })
          else
            toast("Something went wrong", { type: 'error' });
        })
    } else {
      toast('Please login to add item to cart', { type: 'error' });
    }
  }

  const handlePriceFilter = () => {
    /*  if(minPrice <= 0){
         toast('Invalid minimum price in filter',{type:'error'});
         return false;
     }
     if(minPrice > maxPrice){
         toast('Maximum price should be greater than or equal to minimum price.',{type:'error'});
         return false;
     } */
    let categories = params.category;
    if (selectedCategories.length > 0) {
      categories = selectedCategories.join(",");
    }
    let brands = '';
    if (selectedBrands.length > 0) {
      brands = selectedBrands.join(",");
    }
    getItems(categories, brands);
  }

  const setPerPageValue = (e, count) => {
    e.preventDefault();
    setPerPage(count);
    setShowSortByItemCount(false);
  }
  const setFilterValue = (e, value) => {
    e.preventDefault();
    setFilter(value);
    setShowSortByList(false);
  }

  return (
    <main className="main" style={{ transform: "none" }}>
      <div className="page-header mt-30 mb-50">
        <div className="container">
          <div className="archive-header">
            <div className="row align-items-center">
              <div className="col-xl-3">
                <h1 className="mb-15" style={{ width: 'max-content' }}>{category?.main_category?.category_name}</h1>
                <div className="breadcrumb">
                  <Link to="/" rel="nofollow">
                    <i className="fi-rs-home mr-5" />
                    Home
                  </Link>
                  <span /> Shop <span /> {category?.main_category?.category_name}
                </div>
              </div>
              <div className="col-xl-9 text-end d-none d-xl-block">
                <ul className="tags-list">
                  {
                    selectedBrands.map((brand, index) => {
                      return (
                        <li className="hover-up">
                          <a href="" onClick={(e) => e.preventDefault()}>
                            {/* <i className="fi-rs-cross mr-10" /> */}
                            Brand: {brands.map((_b) => { if (_b.id == brand) { return _b.brand_name } })}
                          </a>
                        </li>
                      );

                    })
                  }

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-30" style={{ transform: "none" }}>
        <div className="row flex-row-reverse" style={{ transform: "none" }}>
          <div className="col-lg-4-5">
            <div className="shop-product-fillter">
              <div className="totall-product">
                <p>
                  We found <strong className="text-brand">{items.length}</strong> items for you!
                </p>
              </div>
              <div className="sort-by-product-area">
                <div className="sort-by-cover mr-10">
                  <div className="sort-by-product-wrap" onClick={() => setShowSortByItemCount(!showSortByItemCount)}>
                    <div className="sort-by">
                      <span>
                        <i className="fi-rs-apps" />
                        Show:
                      </span>
                    </div>
                    <div className="sort-by-dropdown-wrap">
                      <span>
                        {" "}
                        {perPage} <i className="fi-rs-angle-small-down" />
                      </span>
                    </div>
                  </div>
                  <div className={"sort-by-dropdown" + (showSortByItemCount ? ' show' : '')}>
                    <ul>
                      <li>
                        <a className={perPage == 10 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 10)} href="">
                          10
                        </a>
                      </li>
                      <li>
                        <a href="" className={perPage == 20 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 20)}>20</a>
                      </li>
                      <li>
                        <a href="" className={perPage == 30 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 30)}>30</a>
                      </li>
                      <li>
                        <a href="" className={perPage == 40 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 40)}>40</a>
                      </li>
                      <li>
                        <a href="" className={perPage == 50 ? 'active' : ''} onClick={(e) => setPerPageValue(e, 50)}>50</a>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="sort-by-cover">
                  <div className="sort-by-product-wrap" onClick={() => setShowSortByList(!showSortByList)}>
                    <div className="sort-by">
                      <span>
                        <i className="fi-rs-apps-sort" />
                        Sort by:
                      </span>
                    </div>
                    <div className="sort-by-dropdown-wrap">
                      <span>
                        {" "}
                        {filter} <i className="fi-rs-angle-small-down" />
                      </span>
                    </div>
                  </div>
                  <div className={"sort-by-dropdown" + (showSortByList ? ' show' : '')}>
                    <ul>
                      <li>
                        <a href="" className={filter === 'Select Filter' ? 'active' : ''} onClick={(e) => setFilterValue(e, 'Select Filter')}>
                          Select Filter
                        </a>
                      </li>
                      <li>
                        <a href="" className={filter === 'Price: Low to High' ? 'active' : ''} onClick={(e) => setFilterValue(e, 'Price: Low to High')}>Price: Low to High</a>
                      </li>
                      <li>
                        <a href="" className={filter === 'Price: High to Low' ? 'active' : ''} onClick={(e) => setFilterValue(e, 'Price: High to Low')}>Price: High to Low</a>
                      </li>
                      <li>
                        <a href="" className={filter == 'Release Date' ? 'active' : ''} onClick={(e) => setFilterValue(e, 'Release Date')}>Release Date</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row product-grid">
              {
                items.map((item, index) => {
                  return (
                    <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                      <div className="product-cart-wrap mb-30">
                        <div className="product-img-action-wrap">
                          <div className="product-img product-img-zoom">
                            <Link to={"/product/" + item.id + "/" + item.item_name}>
                              <img
                                className="default-img"
                                src={item?.image ? process.env.REACT_APP_BASE_URL + "/storage/products/" + item.image : process.env.REACT_APP_BASE_URL + "/storage/products/no-image.png"}
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="product-action-1">
                            <a
                              aria-label="Add To Wishlist"
                              className="action-btn"
                              href=""
                              onClick={(e) => { toggleWishlist(item, e) }}
                            >
                              {
                                wishlistItems.includes(item.id) ?
                                  <i className="wisilist-fill">&#10084;</i>
                                  :
                                  <i className="fi-rs-heart" />
                              }
                            </a>
                          </div>
                        </div>
                        <div className="product-content-wrap">
                          <div className="product-category">
                            <Link to={"/product-category/" + item.category?.slug}>{item.category?.category_name}</Link>
                          </div>
                          <h2>
                            <Link to={"/product/" + item.id + "/" + item.item_name}>
                              {item.item_name}
                            </Link>
                          </h2>
                          <div className="product-rate-cover">
                            <div className="product-rate d-inline-block">
                              <div className="product-rating" style={{ width: "90%" }} />
                            </div>
                            <span className="font-small ml-5 text-muted"> (4.0)</span>
                          </div>
                          <div>
                            <span className="font-small text-muted">
                              By <a>{item.brand?.brand_name}</a>
                            </span>
                          </div>
                          <div className="product-card-bottom">
                            <div className="product-price">
                              <span>${item.purchase_price}</span>
                              <span className="old-price">${item.sales_price}</span>
                            </div>
                            <div className="add-cart">
                              <a className="add" href="" onClick={(e) => handleAddToCart(item, e)}>
                                <i className="fi-rs-shopping-cart mr-5" />
                                Add{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              }
              {
                items.length == 0 ?
                  <h5 className="text-center">No Item Available</h5> : null
              }


            </div>
            {/*product grid*/}
            {
              items.length > 0
                ?
                <div className="pagination-area mt-20 mb-20">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-start">
                      <li className="page-item">
                        <a className={"page-link" + (page == 1 ? ' link-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); setPage(page - 1) }}>
                          <i className="fi-rs-arrow-small-left" />
                        </a>
                      </li>

                      <li className="page-item active">
                        <a className="page-link" href="#">
                          {page}
                        </a>
                      </li>

                      <li className="page-item">
                        <a className={"page-link" + (perPage != items.length ? ' link-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); setPage(page + 1) }}>
                          <i className="fi-rs-arrow-small-right" />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                : null
            }

          </div>
          <div
            className="col-lg-1-5 primary-sidebar sticky-sidebar"
            style={{
              position: "relative",
              overflow: "visible",
              boxSizing: "border-box",
              minHeight: 1
            }}
          >
            {/* Fillter By Price */}
            {/* Product sidebar Widget */}
            <div
              className="theiaStickySidebar"
              style={{
                paddingTop: 0,
                paddingBottom: 1,
                position: "static",
                transform: "none",
                top: 0,
                left: "159.5px"
              }}
            >
              <div className="sidebar-widget widget-category-2 mb-30">
                <h5 className="section-title style-1 mb-30">Category</h5>
                <ul>
                  <li>
                    <a href="shop-grid-right.html">
                      {" "}
                      <img src="assets/imgs/theme/icons/category-1.svg" alt="" />
                      Milks &amp; Dairies
                    </a>
                    <span className="count">30</span>
                  </li>
                  <li>
                    <a href="shop-grid-right.html">
                      {" "}
                      <img src="assets/imgs/theme/icons/category-2.svg" alt="" />
                      Clothing
                    </a>
                    <span className="count">35</span>
                  </li>
                  <li>
                    <a href="shop-grid-right.html">
                      {" "}
                      <img src="assets/imgs/theme/icons/category-3.svg" alt="" />
                      Pet Foods{" "}
                    </a>
                    <span className="count">9</span>
                  </li>
                  <li>
                    <a href="shop-grid-right.html">
                      {" "}
                      <img src="assets/imgs/theme/icons/category-4.svg" alt="" />
                      Baking material
                    </a>
                    <span className="count">15</span>
                  </li>
                  <li>
                    <a href="shop-grid-right.html">
                      {" "}
                      <img src="assets/imgs/theme/icons/category-5.svg" alt="" />
                      Fresh Fruit
                    </a>
                    <span className="count">87</span>
                  </li>
                </ul>
              </div>
              <div className="sidebar-widget  range mb-30">
                <h5 className="section-title style-1 mb-30">Fill by price</h5>
                <div className="price-filter">
                  {/* <div className="price-filter-inner">
                <div
                  id="slider-range"
                  className="mb-20 noUi-target noUi-ltr noUi-horizontal noUi-background"
                >
                  <div className="noUi-base">
                    <div
                      className="noUi-origin noUi-connect"
                      style={{ left: "25%" }}
                    >
                      <div className="noUi-handle noUi-handle-lower" onMouseMove={(e) => console.log("MOUSE MOVE", e)} onDragOver={(e) => console.log("EVENT",e)} />
                    </div>
                    <div
                      className="noUi-origin noUi-background"
                      style={{ left: "50%" }}
                    >
                      <div className="noUi-handle noUi-handle-upper" />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="caption">
                    From:{" "}
                    <strong id="slider-range-value1" className="text-brand">
                      $500
                    </strong>
                  </div>
                  <div className="caption">
                    To:{" "}
                    <strong id="slider-range-value2" className="text-brand">
                      $1,000
                    </strong>
                  </div>
                </div>
              </div> */}

                  <div className="price-filter-inner">
                    <div className="row pb-1">
                      <div className="col">
                        <input type="number" placeholder="Min Price" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} className="form-control-xs" name="" id="" />
                      </div>
                      <div className="col">
                        <input type="number" placeholder="Max Price" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} className="form-control-xs" name="" id="" />
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="caption">
                        From:{" "}
                        <strong id="slider-range-value1" className="text-brand">
                          ${minPrice}
                        </strong>
                      </div>
                      <div className="caption">
                        To:{" "}
                        <strong id="slider-range-value2" className="text-brand">
                          ${maxPrice}
                        </strong>
                      </div>
                    </div>
                    <a href="" onClick={(e) => { e.preventDefault(); handlePriceFilter() }} className="btn btn-sm btn-default">
                      <i className="fi-rs-filter mr-5" /> Fillter
                    </a>
                  </div>
                </div>

              </div>

              {/* Filter by Brand */}
              <div className="sidebar-widget range mb-30">
                <h5 className="section-title style-1 mb-0">Brand</h5>

                <div className="list-group">
                  <div ref={brandRef} className={"list-group-item mb-10 mt-10" + (showAllBrands ? ' see-all-brands' : '')}>
                    <div className="custome-checkbox">
                      {
                        brands.map((brand, index) => {
                          if (showAllBrands) {
                            return (
                              <>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="checkbox"
                                  id={"brand-" + index}
                                  value={brand.brand_name}
                                  checked={selectedBrands.includes(brand.id)}
                                  onChange={() => handleBrandChange(brand.id)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"brand-" + index}
                                >
                                  <span>{brand.brand_name}</span>
                                </label>
                                <br />
                              </>
                            );
                          } else {
                            if (index < 10) {
                              return (
                                <>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="checkbox"
                                    id={"brand-" + index}
                                    value={brand.brand_name}
                                    onChange={() => handleBrandChange(brand.id)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"brand-" + index}
                                  >
                                    <span>{brand.brand_name}</span>
                                  </label>
                                  <br />
                                </>
                              );
                            }
                          }
                        })
                      }
                    </div>
                  </div>
                  {brands.length > 10 ?
                    <a href="" onClick={(e) => { e.preventDefault(); setShowAllBrands(!showAllBrands) }}>{showAllBrands ? 'See less...' : 'See all...'}</a>
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ProductCategory;